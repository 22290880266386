@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed:300i,400,700');
@import './helpers/_helpers.scss';

#root {
    overflow-x: hidden;
    .cookieConsent {
        font-family: 'IBM Plex Sans Condensed', sans-serif !important;
        @include respond-above(md) {
            width: 900px !important;
            margin: 0 auto !important;
            right: 0 !important;
            bottom: 30px !important;
            text-align: center !important;
        }
    }
}
body {
    margin: 0;
    padding: 0;
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-size: 14px;
}

html {
    background-color: $bgColor;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    box-sizing: border-box;
}
