// THEME ONE
$white: #e7e7e7;
$yellow: rgb(235, 199, 78);
$gold: #b09759;
$lightyellow: #ebc64e71;
// $mint: #16a085;
$mint: #47beae;
$black: #181818;
$color4: rgba(245, 206, 206, 0.829);

$title1: $yellow;
$title2: $lightyellow;
$title3: $gold;

$fontColor: $white;
$bgColor: $black;
/* Small devices (portrait tablets and large phones, 600px and up) */
$small: 600px;
/* Medium devices (landscape tablets, 768px and up) */
$medium: 768px;
/* Large devices (laptops/desktops, 992px and up) */
$large: 992px;
/* Extra large devices (large laptops and desktops, 1200px and up) */
$xlarge: 1200px;
