@import './helpers/_helpers.scss';

.modal,
.job-modal {
    width: 100%;
    padding: 20px;
    font-weight: 700;
    position: relative;

    a {
        text-decoration: underline;
        font-size: 0.83em;
        text-transform: uppercase;
    }
    .job-about,
    .job {
        padding: 0;
        color: white;
        &.rightAlign {
            text-align: right;
            p {
                text-align: inherit;
                margin-left: auto;
            }
        }
        h3 {
            @include outline($title3, $bgColor);
        }
        font-size: 1rem;
        font-weight: normal;
        @include respond-above(md) {
            p {
                width: 70%;
            }
            font-size: 1.5rem;
        }
    }

    .job-period {
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 400;
        color: $fontColor;
        border-left: 3px solid $title1;
        padding-left: 10px;
        margin-left: 10px;
        span {
            font-size: 1.5rem;
        }
        @include respond-below(md) {
            padding-right: 4rem;
        }
        @include respond-above(md) {
            font-size: 3rem;
            span {
                font-size: 2rem;
            }
        }
        sup {
            font-size: 0.5rem;
            text-transform: uppercase;
            color: $mint;
            text-shadow: none;
            @include respond-above(md) {
                font-size: 1rem;
            }
        }
    }
    .job-company {
        font-size: 10.5rem;
        flex: 1;
        padding: 1%;
        text-align: left;
        text-transform: uppercase;
        @include outline($title1, $bgColor);
    }
    @include respond-above(md) {
        height: 100%;
        padding: 35px 50px;

        .job-header {
            display: flex;
        }
    }
}
