@import './helpers/_helpers.scss';

a {
    text-decoration: none;
    color: $white;
    cursor: pointer;
}
*:focus,
*:active {
    outline: none;
}

// #root {
//     background-color: $bgColor;
//     height: 100%;
//     min-height: 100vh;
//     width: 100vw;
//     overflow: hidden;
// }
