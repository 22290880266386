@keyframes rightandLeft {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(29%);
    }

    100% {
        transform: translateX(0%);
    }
}
@keyframes sliderArrowNext {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-29%);
    }

    100% {
        transform: translateX(0%);
    }
}
@keyframes sliderArrowNext2 {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(29%);
    }

    100% {
        transform: translateX(0%);
    }
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        transform: translate3d(0px, 100%, 0px);
    }

    100% {
        position: fixed;
    }
}

@keyframes upanddown {
    0% {
        position: fixed;
        transform: translateY(0);
    }

    50% {
        position: fixed;
        transform: translateY(-29%);
    }

    100% {
        position: fixed;
        transform: translateY(0%);
    }
}
@keyframes downandup {
    0% {
        transform: translateY(-150%);
    }

    50% {
        transform: translateY(-175%);
    }

    100% {
        transform: translateY(-150%);
    }
}
