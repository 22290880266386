@import './helpers/_helpers.scss';

.close {
    width: 50px;
    height: 50px;
    right: 20px;
    top: 20px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    z-index: 200;
    .icon {
        background-image: url(../assets/svg/close.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 100%;
        height: 100%;

        @include respond-above(md) {
            transform: scale(1.4);
            height: 30px;
            width: 30px;
            right: 30px;
        }
    }
}
