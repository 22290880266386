@import './helpers/_helpers.scss';

.work {
    .jobs {
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        font-size: 1rem;

        @include respond-above(md) {
            display: flex;
        }

        width: 100%;

        .job-roles {
            @include respond-above(md) {
                margin-right: 8rem;
            }
        }

        .job {
            font-weight: 700;
            padding-left: 0px;
            white-space: nowrap;
            overflow: hidden;
            h1 {
                sup {
                    font-size: 0.2em;
                    color: $title1;
                    margin-left: 10px;
                }
            }
        }
    }
}
