@mixin outline($outlinecolor, $color) {
    color: $color;
    text-shadow: -1px 0 $outlinecolor, 0 1px $outlinecolor, 1px 0 $outlinecolor, 0 -1px $outlinecolor;
}

//When using ::before and ::after you'll always need these three,
//so we're saving two lines of code every time you use this.
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

// We use this for creating scalable elements (usually images / background images) that maintain a ratio
@mixin responsive-ratio($x, $y, $pseudo: false) {
    $padding: unquote(($y / $x) * 100 + '%');
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

// A map of breakpoints.
$breakpoints: (
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
);
// Respond above.
@mixin respond-above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-between($lower, $upper) {
    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
        // Get the lower and upper breakpoints.
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        // Write the media query.
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }

        // If one or both of the breakpoints don't exist.
    } @else {
        // If lower breakpoint is invalid.
        @if (map-has-key($breakpoints, $lower) == false) {
            // Log a warning.
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        // If upper breakpoint is invalid.
        @if (map-has-key($breakpoints, $upper) == false) {
            // Log a warning.
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}
